<template>
    <zw-input-group v-model="inputVal.seconds"
                    name="wait_seconds"
                    :label-prefix="labelPrefix"
                    validate="required"
                    type="number"
    ></zw-input-group>
</template>

<script>

export default {
    name: 'SetTimerOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>